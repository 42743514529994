'use client';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
type Props = {
  category?: String[];
};

export default function Category({ category }: Props) {
  if (!category) {
    return null;
  }
  return (
    <>
      <Stack direction="row" spacing={1}>
        {category.map((item, i) => {
          return <Chip key={i} label={item} color="primary" />;
        })}
      </Stack>
    </>
  );
}
